import { Routes, Route } from "react-router-dom";

import MainPage from "../../pages/MainPage";
import AboutUsPage from "../../pages/AboutUsPage";
import CategoriesPage from "../../pages/CategoriesPage";
import ProductsPage from "../../pages/ProductsPage";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Box, Flex } from "@chakra-ui/react";
import Login from "../../pages/Login";
import Singup from "../../pages/Singup";
import ComingSoonPage from "../../pages/ComingSoonPage";
import ConfirmEmailPage from "../../pages/ConfirmEmailPage";
import { useEffect } from "react";
import ForgotPassword from "../../pages/ForgotPassword";
import getUserFromLocalStorage from "../../utils/getUsetFromLocalStorage";
import { transformUserData } from "../../utils/transformUserData";
// import LoginToAllSite from "../LoginToAllSite/LoginToAllSite";
import SearchPage from "../../pages/SearchPage";
import { Helmet } from "react-helmet";
import { useAtom } from "jotai";
import { rememberUser, userInfoAtom } from "../../atom/user";
import FaqPage from "../../pages/Faq";

function App() {
  
  const [, setUserId] = useAtom(userInfoAtom);
  const [rememberMe] = useAtom(rememberUser);

  useEffect(() => {
    getUserFromLocalStorage(rememberMe)
      .then((attributes) => {
        setUserId(transformUserData(attributes));
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);



  return (
    <Flex flexDirection={"column"} minH={"100%"}>
      <Helmet>
        <title>Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>

      {/* <LoginToAllSite  /> */}
      <Header />
      <Box flex={"1 1 auto"}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path=":categories" element={<CategoriesPage />} />
          <Route path=":categories/:calibers" element={<ProductsPage />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Singup />} />
          <Route path="coming-soon" element={<ComingSoonPage />} />
          <Route path="confirm-email/:userSub" element={<ConfirmEmailPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="search" element={<SearchPage />} />
          <Route path="faq" element={<FaqPage />} />
        </Routes>
      </Box>
      <Footer />
    </Flex>
  );
}

export default App;
