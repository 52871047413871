import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import ProductsList from "../components/ProductList/ProductList";

import FiltersDesktop from "../components/Filters/FiltersDesktop";
import FiltersMobile from "../components/Filters/mobile/FiltersMobile";

import { memo } from "react";
import { Helmet } from "react-helmet";
import InfoOnProductsPage from "../components/InfoOnProductsPage/InfoOnProductsPage";
import RefreshPopup from "../components/RefreshPopup/RefreshPopup";
import LoginToAllSite from "../components/LoginToAllSite/LoginToAllSite";

const ProductsPage = () => {
  const location = useLocation();
  const currentCaliber = decodeURIComponent(location.pathname)
    .split("/")
    .slice(2)
    .join("/");

  return (
    <>
    <LoginToAllSite  />
      <RefreshPopup />
      <Flex
        maxW={"1240px"}
        w={"100%"}
        mx={"auto"}
        pt={{ base: "22px", lg: "45px" }}
        flexDirection={"column"}
        mb={{ base: "72px", md: "64px" }}
        px={{ base: "22px", lg: "10px" }}
      >
        <Helmet>
          <title>{currentCaliber}</title>
          <meta
            name="description"
            content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
          />
        </Helmet>
        <InfoOnProductsPage />
        <Flex justifyContent={"space-between"} w={"100%"} gap={"20px"}>
          {window.innerWidth > 991 && <FiltersDesktop />}

          <ProductsList />
        </Flex>

        {window.innerWidth < 992 && <FiltersMobile />}
      </Flex>
    </>
  );
};

export default memo(ProductsPage);
